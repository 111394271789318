exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-checkout-index-js": () => import("./../../../src/pages/cart/checkout/index.js" /* webpackChunkName: "component---src-pages-cart-checkout-index-js" */),
  "component---src-pages-cart-index-js": () => import("./../../../src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-confirm-js": () => import("./../../../src/pages/orderConfirm.js" /* webpackChunkName: "component---src-pages-order-confirm-js" */),
  "component---src-templates-category-index-js": () => import("./../../../src/templates/category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-shop-index-js": () => import("./../../../src/templates/shop/index.js" /* webpackChunkName: "component---src-templates-shop-index-js" */),
  "component---src-templates-store-page-index-js": () => import("./../../../src/templates/store-page/index.js" /* webpackChunkName: "component---src-templates-store-page-index-js" */)
}

