import React, { createContext, useState, useEffect } from 'react';
import CartStorage from '../helpers/storage';

const defaultState = {
  open: false,
  product: null,
  numberOfProducts: 0
};

export const NotificationContext = createContext(defaultState);

export const NotificationProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  const getNumberOfProducts = async () => {
    const cart = new CartStorage();
    const products = await cart.getAllItems();

    return products?.length;
  }

  const showNotification = async (product = null) => {
    const numberOfProducts = await getNumberOfProducts();
    setState({
      ...state,
      open: true,
      numberOfProducts,
      product
    });
  };

  const closeNotification = async () => {
    const numberOfProducts = await getNumberOfProducts();
    setState({
      ...state,
      open: false,
      numberOfProducts
    });
  };

  const refreshCart = async () => {
    const numberOfProducts = await getNumberOfProducts();
    setState({
      ...state,
      numberOfProducts
    });
  }

  const providerValue = {
    state,
    setState,
    showNotification,
    refreshCart
  };

  useEffect(() => {
    let timerId;
    if (state?.open === true) {
      timerId = setTimeout(() => {
        closeNotification();
        clearTimeout(timerId);
      }, 5000);
    }

    return () => {
      clearTimeout(timerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    const initialState = async () => {
      const numberOfProducts = await getNumberOfProducts();
      if (numberOfProducts !== 0) {
        setState({
          ...state,
          numberOfProducts
        });
      }
    }
    initialState();
  }, [state.product]);

  return (
    <NotificationContext.Provider value={providerValue}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
